<template>
    <AModal
        :visible="visible"
        title="List Distributor"
        @cancel="handleModalCancel"
        :destroy-on-close="true"
        width="70%"
        :footer="null">
        
        <AAlert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <div v-if="state.errors.length" class="mt-2">
            <AAlert
                v-for="(item, index) in state.errors"
                class="mb-2"
                type="error"
                :key="index"
                :message="item.message"
                banner
                closable
                @close="state.errors = []" />
        </div>

        <div class="row justify-content-end mt-4">
                <div class="col-lg-6 col-md-12 mt-auto">
                </div>
                <div class="col-lg-6 col-md-12 text-right mt-auto">
                    <ASpace>
                        <ATooltip
                            v-if="state.action.storages.length"
                            title="Simpan">
                            <AButton
                                type="primary"
                                html-type="submit"
                                :loading="state.action.loading"
                                @click="btnSubmit">
                                Simpan
                            </AButton>
                        </ATooltip>
                    </ASpace>
                </div>
            </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>
                
                <template #action="{ index, record }">
                    <ARadioGroup
                        v-model:value="record.action_status"
                        @input="listenActionButton"
                        :key="index">
                        <ASpace>
                            <ATooltip title="Approve">
                                <ARadioButton
                                    class="approve"
                                    shape="circle"
                                    :id="record.id"
                                    value="approve">
                                    <i class="fa fa-check" aria-hidden="true"/>
                                </ARadioButton>
                            </ATooltip>
                            <ATooltip title="Reject">
                                <ARadioButton
                                    class="reject"
                                    shape="circle"
                                    :id="record.id"
                                    value="reject">
                                    <i class="fa fa-times" aria-hidden="true"/>
                                </ARadioButton>
                            </ATooltip>
                        </ASpace>
                    </ARadioGroup>
                </template>
            </MdTable>
        </div>
    </AModal>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import _ from 'lodash'
import {
    hasRoles,
    ROLE_ADMIN_BK,
    ROLE_TSO,
} from '@/helpers'

export default defineComponent({
    components: {
        // 
    },
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
            }),
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'kode_distributor',
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'nama_distributor',
                },
                {
                    title: 'Kode Toko',
                    dataIndex: 'kode_toko',
                },
                {
                    title: 'Nama Toko',
                    dataIndex: 'nama_toko',
                },
                {
                    title: 'Status',
                    dataIndex: 'status_request',
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                    slots: { customRender: 'action' },
                },
            ],
            endpoint: {
                current: `/api/report-lelang/toko-lelang/distributors`,
                request: `/api/report-lelang/toko-lelang/distributors/save-approval`,
            },
            errors: [],
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            params: {
                q: '',
                customer_id: props.item.customer_id,
                page: 1,
                "per-page": 10,
            },
            action: {
                loading: false,
                storages: [],
            },
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true

            apiClient
                .get(state.endpoint.current, {
                    params: _.pickBy(state.params),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    items.forEach((item) => {
                        item.action_status = null
                    })

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        const btnSubmit = () => {
            if (state.action.storages.length === 0) {
                Modal.warning({
                    content: 'Centang dahulu data yang akan diperbarui!',
                })
                return
            }

            Modal.confirm({
                title: 'Konfirmasi',
                content: 'Apakah Anda ingin melanjutkan penyimpanan?',
                onOk() {
                    state.action.loading = true
                    
                    apiClient.post(state.endpoint.request, {
                            data: state.action.storages,
                        })
                        .then(({ data }) => {
                            if (data.status == 'success') {
                                message.success(data.message)
                            }

                            state.selectedRowKeys = []
                            fetchDataList()
                        })
                        .finally(() => {
                            state.action.loading = false
                        })
                },
                onCancel() { },
            })
        }

        const listenActionButton = (event) => {
            let id = _.toInteger(event.target.getAttribute('id'))
            let status = event.target.value

            let find = state.action.storages.find(item => item.id == id)
            let first = state.data.find(item => item.id == id)
            let isStatus = status == 'approve'

            if (!find && first) {
                state.action.storages.push({
                    ...first,
                    is_approval: isStatus,
                })
            } else {
                find.is_approval = isStatus
            }
        }

        onMounted(() => {
            fetchDataList()

            // show column action only this roles
            if (!hasRoles([ROLE_TSO])) {
                state.columns = state.columns.filter(item => {
                    if (!_.includes(['action'], item.dataIndex)) {
                        return item
                    }
                })
            }
        })

        return {
            fetchDataList,
            state,
            errorMessage,
            handleModalCancel,
            handleTableChange,
            listenActionButton,
            btnSubmit,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
            ROLE_TSO,
        }
    },
});
</script>

<style scoped lang="scss">
.approve,
.reject {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50% !important;

    &.ant-radio-button-wrapper-checked {
        color: white !important;
    }
}
.approve {
    padding: 0px 8px !important;
    color: #009688;
    
    &.ant-radio-button-wrapper-checked {
        background-color: #009688 !important;
        border-color: #009688 !important;
    }
}
.reject {
    padding: 0px 10px !important;
    color: #f44336;
    
    &.ant-radio-button-wrapper-checked {
        background-color: #f44336 !important;
        border-color: #f44336 !important;
    }
}
</style>
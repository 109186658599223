<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol
                v-if="hasRoles([ROLE_ADMIN_BK, ROLE_HELPDESK, ROLE_TSO, ROLE_ASM, ROLE_DISTRIBUTOR, ROLE_SPC, ROLE_SPC_GROUP])"
                :xl="6" :md="8" :sm="12" :span="24">
                <AMonthPicker
                    style="width:100%;"
                    v-model:value="state.params.periode"
                    placeholder="Pilih Periode"
                    format="MMMM YYYY"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24" v-can:hide.distributor>
                <FilterRegional
                    style="width:100%;"
                    v-model:value="state.params.region"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterProvince
                    style="width:100%;"
                    v-model:value="state.params.provinsi"
                    v-model:region="state.params.region"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24" v-can:hide.distributor>
                <FilterArea
                    style="width:100%;"
                    v-model:value="state.params.area"
                    v-model:provinsi="state.params.provinsi"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24" v-can:hide.distributor>
                <FilterKabupatenKota
                    style="width:100%;"
                    v-model:value="state.params.kabupaten"
                    v-model:region="state.params.region"
                    v-model:provinsi="state.params.provinsi"
                    v-model:area="state.params.area"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterToko
                    style="width:100%;"
                    v-model:value="state.params.toko"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <ASelect
                    style="width:100%;"
                    :options="state.statusMappingLists"
                    allow-clear
                    placeholder="Pilih Status Mapping Toko"
                    v-model:value="state.params.status_mapping"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <ASpace>
                    <ATooltip
                        v-if="hasRoles([ROLE_DISTRIBUTOR])"
                        title="Pengajuan">
                        <AButton
                            :loading="state.isActionRequest"
                            @click="btnActionRequest">
                            <span class="fa fa-send mr-2" aria-hidden="true"/> Pengajuan
                        </AButton>
                    </ATooltip>
                    <ATooltip
                        v-if="hasRoles([ROLE_ASM])"
                        title="Approve">
                        <AButton
                            :loading="state.isActionApprove"
                            @click="btnActionApprove"
                            type="primary">
                            <span class="fa fa-check mr-2" aria-hidden="true"/> Approve
                        </AButton>
                    </ATooltip>
                    <ATooltip
                        v-if="hasRoles([ROLE_ASM])"
                        title="Reject">
                        <AButton
                            :loading="state.isActionReject"
                            @click="btnActionReject"
                            type="danger">
                            <span class="fa fa-ban mr-2" aria-hidden="true"/> Reject
                        </AButton>
                    </ATooltip>
                    <ATooltip title="Download Excel">
                        <DownloadExcel
                            :url="state.endpoint.current"
                            :params="queryParams()"
                            @errors="errorMessage"/>
                    </ATooltip>
                </ASpace>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                row-key="customer_id"
                :row-selection="{ selectedRowKeys: state.selectedRowKeys, onChange: onSelectChange }"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>
                <template #action="{ record }">
                    <ASpace>
                        <ATooltip title="List Distributor">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnDistributorList(record)">
                                <i class="fa fa-calendar-check-o" />
                            </AButton>
                        </ATooltip>
                    </ASpace>
                </template>
            </MdTable>
        </div>

        <!-- detail -->
        <DistributorList
            v-if="visibleDistributorListModal"
            v-model:visible="visibleDistributorListModal"
            v-model:item="visibleDistributorListItemModal"/>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import { Modal, message } from 'ant-design-vue'
import apiClient from '@/services/axios'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterArea from '@/components/filter/FilterArea'
import FilterToko from '@/components/filter/FilterToko'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import DistributorList from './DistributorList'
import _ from 'lodash'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/globalmixin'
import {
    ROLE_ADMIN_BK,
    ROLE_HELPDESK,
    ROLE_ASM,
    ROLE_TSO,
    ROLE_DISTRIBUTOR,
    ROLE_SPC,
    ROLE_SPC_GROUP,
    hasRoles,
} from '@/helpers'
import moment from 'moment'

export default defineComponent({
    components: {
        FilterProvince,
        FilterKabupatenKota,
        FilterRegional,
        FilterArea,
        FilterToko,
        DownloadExcel,
        DistributorList,
    },
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Periode',
                    dataIndex: 'periode',
                },
                {
                    title: 'Region',
                    dataIndex: 'regional',
                },
                {
                    title: 'ID Toko',
                    dataIndex: 'customer_code',
                },
                {
                    title: 'Nama Toko',
                    dataIndex: 'customer_name',
                },
                {
                    title: 'Alamat',
                    dataIndex: 'address',
                },
                {
                    title: 'Area',
                    dataIndex: 'area',
                },
                {
                    title: 'Kota',
                    dataIndex: 'city',
                },
                {
                    title: 'Provinsi',
                    dataIndex: 'province',
                },

                {
                    title: 'SSM',
                    dataIndex: 'user_ssm',
                },
                {
                    title: 'ASM',
                    dataIndex: 'user_sm',
                },
                {
                    title: 'TSO',
                    dataIndex: 'user_am',
                },
                {
                    title: 'Status Mapping Toko',
                    dataIndex: 'status_mapping',
                },
                {
                    title: 'Distributor Baru',
                    dataIndex: 'distributor_baru',
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                    slots: { customRender: 'action' },
                },
            ],
            endpoint: {
                current: '/api/report-lelang/toko-lelang',
                request: '/api/report-lelang/toko-lelang/request-pengajuan',
                approve: '/api/report-lelang/toko-lelang/request-approve',
                reject: '/api/report-lelang/toko-lelang/request-reject',
            },
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            isActionRequest: false,
            isActionApprove: false,
            isActionReject: false,
            params: extractQueryParams({
                q: '',
                region: [],
                provinsi: [],
                periode: moment(new Date()).startOf('month').format('YYYY-MM'),
                area: [],
                kabupaten: [],
                toko: [],
                status_mapping: null,
                page: 1,
                "per-page": 10,
            }),
            statusMappingLists: [
                { value: 1, label: 'Belum Termapping' },
                { value: 2, label: 'Proses Pengajuan' },
                { value: 3, label: 'Menunggu Approval' },
                { value: 4, label: 'Sudah Termapping' },
            ],
            selectedRows: [],
            selectedRowKeys: [],
        })

        const onSelectChange = (keys, rows) => {
            state.selectedRowKeys = keys
            state.selectedRows = rows
        }

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize
            
            fetchDataList()
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.periode) {
                params.periode = moment(state.params.periode).format('YYYY-MM')
            }

            return updateUrlWithQuery(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get(state.endpoint.current, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data
                    
                    items.forEach((item, i) => {
                        item.key = i
                        item['progress_download'] = false
                    })

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        const payloadActionButton = () => {
            return {
                data: _.map(state.selectedRows, ({ periode, customer_code, customer_id }) => ({
                    periode,
                    customer_code,
                    customer_id,
                })),
            }
        }

        const btnActionRequest = () => {
            if (state.selectedRowKeys.length === 0) {
                Modal.warning({
                    content: 'Centang dahulu data yang akan diperbarui!',
                })
                return
            }

            Modal.confirm({
                title: 'Konfirmasi Pengajuan',
                content: 'Apakah Anda ingin pengajuan?',
                onOk() {
                    state.isActionRequest = true

                    apiClient.post(state.endpoint.request, payloadActionButton())
                        .then(({ data }) => {
                            if (data.message) {
                                message.success(data.message)
                            }
                            
                            state.selectedRowKeys = []
                        })
                        .finally(() => {
                            state.isActionRequest = false
                        })
                },
                onCancel() { },
            })
        }

        const btnActionApprove = () => {
            if (state.selectedRowKeys.length === 0) {
                Modal.warning({
                    content: 'Centang dahulu data yang akan diperbarui!',
                })
                return
            }

            Modal.confirm({
                title: 'Konfirmasi Approve',
                content: 'Apakah Anda ingin approve?',
                onOk() {
                    state.isActionApprove = true

                    apiClient.post(state.endpoint.approve, payloadActionButton())
                        .then(({ data }) => {
                            if (data.message) {
                                message.success(data.message)
                            }
                            
                            state.selectedRowKeys = []
                        })
                        .finally(() => {
                            state.isActionApprove = false
                        })
                },
                onCancel() { },
            })
        }

        const btnActionReject = () => {
            if (state.selectedRowKeys.length === 0) {
                Modal.warning({
                    content: 'Centang dahulu data yang akan diperbarui!',
                })
                return
            }

            Modal.confirm({
                title: 'Konfirmasi Reject',
                content: 'Apakah Anda ingin reject?',
                onOk() {
                    state.endpoint.isActionReject = true

                    apiClient.post(state.endpoint.reject, payloadActionButton())
                        .then(({ data }) => {
                            if (data.message) {
                                message.success(data.message)
                            }
                            
                            state.selectedRowKeys = []
                        })
                        .finally(() => {
                            state.endpoint.isActionReject = false
                        })
                },
                onCancel() { },
            })
        }

        // handle distributor list
        const visibleDistributorListModal = ref(false);
        const visibleDistributorListItemModal = ref(null);

        const btnDistributorList = (item = null) => {
            visibleDistributorListModal.value = true
            visibleDistributorListItemModal.value = item
        }

        // handle vue
        onMounted(() => {
            fetchDataList()
        })

        return {
            fetchDataList,
            state,
            onSelectChange,
            handleTableChange,
            errorMessage,
            queryParams,
            btnActionRequest,
            btnActionApprove,
            btnActionReject,
            btnDistributorList,
            visibleDistributorListModal,
            visibleDistributorListItemModal,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
            ROLE_HELPDESK,
            ROLE_ASM,
            ROLE_TSO,
            ROLE_DISTRIBUTOR,
            ROLE_SPC,
            ROLE_SPC_GROUP,
        }
    },
})
</script>
